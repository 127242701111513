export const environment = {
    production: true,
    uxUI: 'https://demandxchange-portal.pureincubation.com',
    ssoApiUrl: "https://api.contenthub-staging.demandscience-apps.com/api/user",
    authUrl: "https://api.contenthub-staging.demandscience-apps.com/api/auth",
    dxCampaignSyncUrl: "https://api.contenthub-staging.demandscience-apps.com/api",
    dxGraphQlUri: "https://graphql.contenthub-staging.demandscience-apps.com/graphql",
    powerbiConfig: {
      powerbiUrl: 'https://qa-px2-px-api-powerbi-api.pureincubation.com/api',
      powerbiServiceAuth: {
        tokenCS: 'bXFhbjBlaG04bzFlN3RmdWpmc280c3RsajpqYm9pMjJtNnB1Y2tiNGJhYmkycmVucXZhc3Q5NHRyYjl0c24yZGJkZW9ycGFiZzdwaTQ=',
        tokenURL: 'https://dev-bre-pureincubation.auth.us-east-1.amazoncognito.com/oauth2/token',
        authBodyFormat: 'grant_type=client_credentials&scope=bre.api.com/api.execute'
      },
      defualtWorspaceId: '7e884027-e236-4dbe-a2d5-e98494ab6dd9',
      defaultReportId: '78244952-92cf-444b-bab7-01d40e94312a',
      lightReportId: '5b4c5ccf-b86d-4dd6-b689-c8cd58a2c10d',
      convertrReportId: '66d8199f-59fc-4f69-85c0-357077d1fac4',
      convertrPBRId: '30bc74fe-216c-4941-8103-bffa3f2077ad',
      newreportPBRId: '2167f4c7-797a-42da-93cd-8a9e4d074bec',
      newreportERId: 'c4678539-6fdf-4afa-a314-36368c43ff0f',
      convertrCFId: 'effe488b-cfaa-45a2-9800-012fa9417a28',
      newreportCFId: '389eecc9-e818-472c-b297-2f138dfc2433',
      convertrPMId: '82c48916-f5b5-4964-9ef9-ebccbe5c0eeb',
      convertrILId: 'd2149062-a6fe-4def-958d-5c653c71abf0',
      newreportCPRId: '5185bbb6-a3c1-475e-8595-bf57084acb5a',
      newreportPMId: '0bc48b28-b7e9-44fd-9f06-07bf7b2eee77',
      newreportUQDId: '2213d0e8-0467-4f1e-822d-758714028915',
      converterFD: '361ebb7f-4e26-4d1b-b775-1a5f85726aad'
    }
};
